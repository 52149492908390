<template>
  <div class="mainpage">
    <ToolBar/>
</div>
</template>



<script>
import ToolBar from '../../components/toolbar/ToolBar';
  export default {
    name: 'mainPage',
    components:{
      ToolBar
    },
    data: () => ({
      colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
    }),
  }
</script>
<style lang="scss" scoped>
.mainpage{
  max-width: 100%;
  max-height: 100%;
}

</style>