
<template>
  <div>
   <PreLoader v-if="isLoading"/>
   <div v-show="!isLoading" class="site-wrap" id="home-section">
      <!--<topHeader/>-->
      <div class="site-mobile-menu site-navbar-target">
                <div class="site-mobile-menu-header">
                    <div class="site-mobile-menu-close mt-3">
                    <span class="icon-close2 js-menu-toggle"></span>
                    </div>
                </div>
                <div class="site-mobile-menu-body">
                    <ul class="site-nav-wrap">
                    <li class="has-children">
                        <span class="arrow-collapse collapsed" data-toggle="collapse" data-target="#collapseItem0"></span>
                        <a href="#" class="nav-link">Главная<v-icon>mdi-menu</v-icon></a>
                        <ul class="collapse" id="collapseItem0">
                            <li>
                                <a href="/#mission-section" class="nav-link">Биз жөнүндө</a>
                            </li>
                            <li>
                                <a href="/#press-section" class="nav-link">Соңку маалыматтар</a>
                            </li>
                            <li>
                                <a href="/#testimonials-section" class="nav-link">Отзывдар</a>
                            </li>
                        </ul>
                    </li>
                    <li><a href="#english-section" class="nav-link">English language</a>
                    </li>
                    <li><a href="#exam-section" class="nav-link">ЖРТ</a></li>
                    <li><a href="#actual-section" class="nav-link">Актуладуу маалыматтар</a></li>
                    <li><a href="#faq-section" class="nav-link">Служба поддержки</a></li>
                    </ul>
                </div>
            </div>
            <div class="top-bar">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <a href="mailto:Zhyldyzacademy@protonmail.com" class="text-white">
                                <span class="mr-2 text-white icon-envelope-open-o"><v-icon color="#fff">mdi-gmail</v-icon></span> 
                                <span class="d-none d-md-inline-block">Zhyldyzacademy@protonmail.com</span>
                            </a>
                            <span class="mx-md-2 d-inline-block"></span>
                            <a href="#" @click="window.open('tel:+996555949501 ')" class="text-white">
                                <span class="mr-2 text-white icon-phone"><v-icon color="#fff">mdi-phone</v-icon></span>
                                <span class="d-none d-md-inline-block">+996 555 94 95 01 </span>
                            </a>
                            <div class="float-right">
                                <a href="https://www.facebook.com/zhyldyz.academy" target="_blank" class="text-white">
                                    <span class="mr-2 text-white icon-facebook"><v-icon color="#fff">mdi-facebook</v-icon></span>
                                    <span class="d-none d-md-inline-block">Facebook</span>
                                </a>
                                <span class="mx-md-2 d-inline-block"></span>
                                <a href="https://www.instagram.com/zhyldyz_academy/" target="_blank" class="text-white">
                                    <span class="mr-2 text-white icon-instagram"><v-icon color="#fff">mdi-instagram</v-icon></span>
                                    <span class="d-none d-md-inline-block">Instagram</span>
                                </a>
                                <span class="mx-md-2 d-inline-block"></span>
                                <a href="https://wa.me/+996704949501" target="_blank" class="text-white">
                                    <span class="mr-2 text-white icon-whatsapp"><v-icon color="#fff">mdi-whatsapp</v-icon></span>
                                    <span class="d-none d-md-inline-block">WhatsApp</span>
                                </a>
                                <a href="https://wa.me/+996704949501" target="_blank" class="text-white" @click="logOut">
                                    <span class="mr-2 text-white icon-whatsapp" ><v-icon color="#fff" style="border: 1px solid white;border-radius:50%; padding:4px;">mdi-account</v-icon> {{loggedIn.displayName}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="sticky-wrapper" class="sticky-wrapper" >
                <header class="site-navbar js-sticky-header site-navbar-target" role="banner" >
                    <div class="container">
                    <div class="row align-items-center position-relative">
                        <div class="site-logo"><img src="../../assets/img/logo.png" alt=""><a href="#home-section" class="text-black"><span class="text-primary">Zhyldyz Academy</span></a></div>
                        <div class="col-12">
                            <nav class="site-navigation text-right ml-auto " role="navigation">
                                <ul class="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block">
                                <li class="has-children">
                                    <a href="#" class="nav-link">Главная <v-icon>mdi-menu</v-icon></a>
                                    <ul class="dropdown arrow-top">
                                        <li><a href="#mission-section" class="nav-link">Биз жөнүндө</a></li>
                                        <li><a href="#press-section" class="nav-link">Соңку маалыматтар</a></li>
                                        <li><a href="#testimonials-section" class="nav-link">Отзывдар</a></li>
                                    </ul>
                                </li>
                                <li><a href="#english-section" class="nav-link">English language</a></li>
                                <li><a href="#exam-section" class="nav-link">ЖРТ</a></li>
                                <li><a href="#actual-section" class="nav-link">Актуладуу маалыматтар</a></li>
                                <li><a href="#faq-section" class="nav-link">Служба поддержки</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="toggle-button d-inline-block d-lg-none"><a href="#" class="site-menu-toggle py-5 js-menu-toggle text-black"><span class="icon-menu h3"><v-icon>mdi-menu</v-icon></span></a></div>
                    </div>
                    </div>
                </header>
            </div>
      <div class="owl-carousel slide-one-item owl-loaded owl-drag">
         <div class="owl-stage-outer">
            <div class="owl-stage" style="transform: translate3d(-2850px, 0px, 0px); transition: all 1.5s ease 0s; width: 8550px;">
               <div class="owl-item cloned" style="width: 1425px;">
                  <div class="site-section-cover overlay img-bg-section" :style="{ 'background-image': 'url(' + require('@/assets/img/hero_3.jpeg') + ')'}">
                     <div class="container">
                        <div class="row align-items-center justify-content-center text-center">
                           <div class="col-md-12 col-lg-7">
                              <h1 data-aos="fade-up" data-aos-delay="" class="aos-init">Welcome to Zhyldyz Academy</h1>
                              <p class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, cumque vitae animi.</p>
                              <p data-aos="fade-up" data-aos-delay="200" class="aos-init"><a href="#" class="btn btn-outline-white border-w-2 btn-md">Get in touch</a></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="owl-item cloned" style="width: 1425px;">
                  <div class="site-section-cover overlay img-bg-section" :style="{ 'background-image': 'url(' + require('@/assets/img/hero_2.jpeg') + ')'}">
                     <div class="container">
                        <div class="row align-items-center justify-content-center text-center">
                           <div class="col-md-12 col-lg-8">
                              <h1 data-aos="fade-up" data-aos-delay="" class="aos-init">New Generation of Mining</h1>
                              <p class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est odit dolorum voluptates!</p>
                              <p data-aos="fade-up" data-aos-delay="200" class="aos-init"><a href="#" class="btn btn-outline-white border-w-2 btn-md">Get in touch</a></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="owl-item active" style="width: 1425px;">
                  <div class="site-section-cover overlay img-bg-section" :style="{ 'background-image': 'url(' + require('@/assets/img/hero_3.jpeg') + ')'}">
                     <div class="container">
                        <div class="row align-items-center justify-content-center text-center">
                           <div class="col-md-12 col-lg-7">
                              <h1 data-aos="fade-up" data-aos-delay="" class="aos-init">Welcome to Zhyldyz Academy</h1>
                              <p class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, cumque vitae animi.</p>
                              <p data-aos="fade-up" data-aos-delay="200" class="aos-init"><a href="#" class="btn btn-outline-white border-w-2 btn-md">Get in touch</a></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="owl-item" style="width: 1425px;">
                  <div class="site-section-cover overlay img-bg-section" :style="{ 'background-image': 'url(' + require('@/assets/img/hero_2.jpeg') + ')'}">
                     <div class="container">
                        <div class="row align-items-center justify-content-center text-center">
                           <div class="col-md-12 col-lg-8">
                              <h1 data-aos="fade-up" data-aos-delay="" class="aos-init">New Generation of Mining</h1>
                              <p class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est odit dolorum voluptates!</p>
                              <p data-aos="fade-up" data-aos-delay="200" class="aos-init"><a href="#" class="btn btn-outline-white border-w-2 btn-md">Get in touch</a></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="owl-item cloned" style="width: 1425px;">
                  <div class="site-section-cover overlay img-bg-section" :style="{ 'background-image': 'url(' + require('@/assets/img/hero_3.jpeg') + ')'}">
                     <div class="container">
                        <div class="row align-items-center justify-content-center text-center">
                           <div class="col-md-12 col-lg-7">
                              <h1 data-aos="fade-up" data-aos-delay="" class="aos-init">Welcome to Zhyldyz Academy</h1>
                              <p class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, cumque vitae animi.</p>
                              <p data-aos="fade-up" data-aos-delay="200" class="aos-init"><a href="#" class="btn btn-outline-white border-w-2 btn-md">Get in touch</a></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="owl-item cloned" style="width: 1425px;">
                  <div class="site-section-cover overlay img-bg-section" :style="{ 'background-image': 'url(' + require('@/assets/img/hero_2.jpeg') + ')'}">
                     <div class="container">
                        <div class="row align-items-center justify-content-center text-center">
                           <div class="col-md-12 col-lg-8">
                              <h1 data-aos="fade-up" data-aos-delay="" class="aos-init">New Generation of Mining</h1>
                              <p class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est odit dolorum voluptates!</p>
                              <p data-aos="fade-up" data-aos-delay="200" class="aos-init"><a href="#" class="btn btn-outline-white border-w-2 btn-md">Get in touch</a></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="owl-nav">
            <div class="owl-prev"><span class="icon-keyboard_arrow_left"></span></div>
            <div class="owl-next"><span class="icon-keyboard_arrow_right"></span></div>
         </div>
         <div class="owl-dots">
            <div class="owl-dot active"><span></span></div>
            <div class="owl-dot"><span></span></div>
         </div>
      </div>
      <div class="site-section">
         <div class="block__73694 mb-2" id="mission-section">
            <div class="container">
               <div class="row d-flex no-gutters align-items-stretch">
                  <div class="col-12 col-lg-6 block__73422 aos-init" :style="{ 'background-image': 'url(' + require('@/assets/img/zhyldyz-academy.jpeg') + ')'}" data-aos="fade-right" data-aos-delay=""></div>
                  <div class="col-lg-5 ml-auto p-lg-5 mt-4 mt-lg-0 aos-init" data-aos="fade-left" data-aos-delay="">
                     <h2 class="mb-3 text-black">Компаниянын милдеттери</h2>
                     <p>- Биздин миссиябыз ар бир окуучунун жана студенттин каалаган жерде туруп онлайн билим алуусун жана ал аркылуу ийгиликке жетүүсүн камсыздоо. Окуучулардын ЖРТга мыкты даярдык көрүүсүнө жана жогорку балл алуусуна жардам берүү. Англис тилин мыкты деңгээлде онлайн түрүндө үйрөнүүгө шарт түзүү жана чет өлкөнүн алдыңкы окуу жайларында окууга мотивация жана жардам берүү.</p>
                  </div>
               </div>
            </div>
         </div>
         <div class="block__73694">
            <div class="container">
               <div class="row d-flex no-gutters align-items-stretch">
                  <div class="col-12 col-lg-6 block__73422 order-lg-2 aos-init" :style="{ 'background-image': 'url(' + require('@/assets/img/zhyldyz-academy-1.jpeg') + ')'}" data-aos="fade-left" data-aos-delay=""></div>
                  <div class="col-lg-5 mr-auto p-lg-5 mt-4 mt-lg-0 order-lg-1 aos-init" data-aos="fade-right" data-aos-delay="">
                     <h2 class="mb-3 text-black">Компаниянын максаты</h2>
                     <p>Биз келечекте бизде окуган ар бир адамдын жашоосун туура багытка өзгөртө алган, турмушта пайдасы тийген гана билимдерди үйрөткөн, эң эффективдүү онлайн курстарды сунуштаган Кыргызстандагы эң мыкты жана алдыңкы академияга айланууну көздөйбүз.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="site-section" id="exam-section">
         <div class="container">
            <div class="row mb-5 justify-content-center">
               <div class="col-md-7 text-center">
                  <div class="block-heading-1 aos-init" data-aos="fade-up" data-aos-delay="">
                     <h2>Жалпы Республикалык Тестирлөө</h2>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-4 col-md-6 mb-4 aos-init" data-aos="fade-up">
                  <div class="block-team-member-1 text-center rounded">
                     <figure><img src="../../assets/img/logic.jpeg" alt="Image" class="img-fluid rounded-circle"></figure>
                     <h3 class="font-size-20 text-black">ЛОГИКА</h3>
                     <span class="d-block font-gray-5 letter-spacing-1 text-uppercase font-size-12 mb-3">ПРИМЕРНО 20 ВИДЕО САБАК</span>
                  </div>
               </div>
               <div class="col-lg-4 col-md-6 mb-4 aos-init" data-aos="fade-up" data-aos-delay="100">
                  <div class="block-team-member-1 text-center rounded">
                     <figure><img src="../../assets/img/grammar.jpeg" alt="Image" class="img-fluid rounded-circle"></figure>
                     <h3 class="font-size-20 text-black">ГРАММАТИКА</h3>
                     <span class="d-block font-gray-5 letter-spacing-1 text-uppercase font-size-12 mb-3">ПРИМЕРНО 20 ВИДЕО</span>
                  </div>
               </div>
               <div class="col-lg-4 col-md-6 mb-4 aos-init" data-aos="fade-up" data-aos-delay="200">
                  <div class="block-team-member-1 text-center rounded">
                     <figure><img src="../../assets/img/reading.jpeg" alt="Image" class="img-fluid rounded-circle"></figure>
                     <h3 class="font-size-20 text-black">ОКУУ ТҮШҮНҮҮ</h3>
                     <span class="d-block font-gray-5 letter-spacing-1 text-uppercase font-size-12 mb-3">20 ВИДЕО</span>
                  </div>
               </div>
               <div class="col-lg-4 col-md-6 mb-4 aos-init" data-aos="fade-up">
                  <div class="block-team-member-1 text-center rounded">
                     <figure><img src="../../assets/img/logic-test.jpeg" alt="Image" class="img-fluid rounded-circle"></figure>
                     <h3 class="font-size-20 text-black">ЛОГИКА</h3>
                     <span class="d-block font-gray-5 letter-spacing-1 text-uppercase font-size-12 mb-3">ТЕСТТЕР</span>
                  </div>
               </div>
               <div class="col-lg-4 col-md-6 mb-4 aos-init" data-aos="fade-up" data-aos-delay="100">
                  <div class="block-team-member-1 text-center rounded">
                     <figure><img src="../../assets/img/grammar-test.jpeg" alt="Image" class="img-fluid rounded-circle"></figure>
                     <h3 class="font-size-20 text-black">ГРАММАТИКА</h3>
                     <span class="d-block font-gray-5 letter-spacing-1 text-uppercase font-size-12 mb-3">ТЕСТТЕР</span>
                  </div>
               </div>
               <div class="col-lg-4 col-md-6 mb-4 aos-init" data-aos="fade-up" data-aos-delay="200">
                  <div class="block-team-member-1 text-center rounded">
                     <figure><img src="../../assets/img/reading-test.jpeg" alt="Image" class="img-fluid rounded-circle"></figure>
                     <h3 class="font-size-20 text-black">ОКУУ ТҮШҮНҮҮ</h3>
                     <span class="d-block font-gray-5 letter-spacing-1 text-uppercase font-size-12 mb-3">ТЕСТТЕР</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <section class="site-section bg-light" id="english-section">
         <div class="container">
            <div class="row mb-5 justify-content-center text-center">
               <div class="col-md-7">
                  <div class="block-heading-1 aos-init" data-aos="fade-up" data-aos-delay="">
                     <h2>English language</h2>
                  </div>
               </div>
            </div>
            <div class="row mb-5">
               <div class="col-md-6 mb-4 mb-lg-0 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="">
                  <div class="pricing">
                     <h3 class="text-center text-black">Beginner level</h3>
                     <p class="text-center"><button @click="toBeginer" class="btn btn-secondary btn-md">Выбрать</button></p>
                  </div>
               </div>
               <div class="col-md-6 mb-4 mb-lg-0 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="100">
                  <div class="pricing">
                     <h3 class="text-center text-black">Elementary level</h3>
                     <p class="text-center"><button @click="toElem" class="btn btn-primary btn-md text-white">Выбрать</button></p>
                  </div>
               </div>
               <div class="col-md-6 mb-4 mb-lg-0 col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="200">
                  <div class="pricing">
                     <h3 class="text-center text-black">Pre-Intermediate level</h3>
                     <p class="text-center"><button @click="goTo ('preIntermediate')" class="btn btn-secondary btn-md">Выбрать</button></p>
                  </div>
               </div>
            </div>
            <!--Login Dialog-->
            <v-dialog v-model="loginModal" max-width="600px" min-width="360px">
               <div>
                  <v-tabs v-model="tab" show-arrows background-color="deep-purple accent-4" icons-and-text dark grow>
                     <v-tabs-slider color="purple darken-4"></v-tabs-slider>
                     <v-tab v-for="i in tabs" :key="i.id">
                           <v-icon large>{{ i.icon }}</v-icon>
                           <div class="caption py-1">{{ i.name }}</div>
                     </v-tab>
                     <v-tab-item>
                           <v-card class="px-4">
                              <v-card-text>
                                 <v-form ref="loginForm" v-model="valid" lazy-validation>
                                       <v-row>
                                          <v-col cols="12">
                                             <v-text-field v-model="email" :rules="loginEmailRules" type="email" label="Электронная почта" required solo append-icon="mdi-account"></v-text-field>
                                          </v-col>
                                          <v-col cols="12">
                                             <v-text-field 
                                                v-model="password" 
                                                :append-icon="show1?'mdi-eye':'mdi-eye-off'" 
                                                :rules="[rules.required, rules.min]" 
                                                :type="show1 ? 'text' : 'password'" 
                                                name="input-10-1" label="Пароль" 
                                                hint="Как минимум 6 символов" 
                                                counter 
                                                solo
                                                @click:append="show1 = !show1"
                                             ></v-text-field>
                                          </v-col>
                                          <v-col class="d-flex" cols="12" sm="6" xsm="12">
                                          </v-col>
                                          <v-spacer></v-spacer>
                                          <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                                             <v-btn :disabled="!valid" color="success" @click="submit"> Войти </v-btn>
                                          </v-col>
                                       </v-row>
                                 </v-form>
                              </v-card-text>
                           </v-card>
                     </v-tab-item>
                     <v-tab-item>
                           <v-card class="px-4">
                              <v-card-text>
                                 <v-form ref="registerForm" v-model="valid" lazy-validation>
                                       <v-row>
                                          <v-col cols="12" sm="6" md="6">
                                             <v-text-field v-model="signupForm.firstName" :rules="[rules.required]" label="Имя" maxlength="20" required solo></v-text-field>
                                          </v-col>
                                          <v-col cols="12" sm="6" md="6">
                                             <v-text-field v-model="signupForm.lastName" :rules="[rules.required]" label="Фамилия" maxlength="20" required solo></v-text-field>
                                          </v-col>
                                          <v-col cols="12">
                                             <v-text-field v-model="signupForm.email" :rules="emailRules" label="Электронная почта" required solo append-icon="mdi-account"></v-text-field>
                                          </v-col>
                                          <v-col cols="12" md="6" lg="6" sm="12">
                                             <v-text-field 
                                                v-model="signupForm.password" 
                                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
                                                :rules="[rules.required, rules.min]" 
                                                :type="show1 ? 'text' : 'password'" 
                                                name="input-10-1" 
                                                label="Пароль" 
                                                hint="Как минимум 6 символов" 
                                                counter
                                                solo
                                                @click:append="show1 = !show1"
                                             ></v-text-field>
                                          </v-col>
                                          <v-col cols="12" md="6" lg="6" sm="12">
                                             <v-text-field 
                                                block 
                                                v-model="verify" 
                                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
                                                :rules="[ rules.required, passwordMatch ]" 
                                                :type="show1 ? 'text' : 'password'" 
                                                name="input-10-1" 
                                                label="Подтвердите пароль" 
                                                counter 
                                                solo
                                                @click:append="show1 = !show1"
                                             ></v-text-field>
                                          </v-col>
                                          <v-spacer></v-spacer>
                                          <v-col class="d-flex" cols="6" sm="12" xsm="12" md="3" lg="4" align-end>
                                             <v-btn :disabled="!valid" color="success" @click="submit">Зарегистрироваться</v-btn>
                                          </v-col>
                                       </v-row>
                                 </v-form>
                              </v-card-text>
                           </v-card>
                     </v-tab-item>
                  </v-tabs>
               </div>
            </v-dialog>
            <!----><!----><!---->
            <div class="row site-section" id="faq-section">
               <div class="col-12 text-center aos-init" data-aos="fade">
                  <h2 class="section-title text-primary">Служба поддержки</h2>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-6">
                  <div class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">
                     <h3 class="text-black h4 mb-4">Can I accept both Paypal and Stripe?</h3>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis perferendis.</p>
                  </div>
                  <div class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">
                     <h3 class="text-black h4 mb-4">What available is refund period?</h3>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis perferendis.</p>
                  </div>
                  <div class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">
                     <h3 class="text-black h4 mb-4">Can I accept both Paypal and Stripe?</h3>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis perferendis.</p>
                  </div>
                  <div class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">
                     <h3 class="text-black h4 mb-4">What available is refund period?</h3>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis perferendis.</p>
                  </div>
               </div>
               <div class="col-lg-6">
                  <div class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">
                     <h3 class="text-black h4 mb-4">Where are you from?</h3>
                     <p>Voluptatum nobis obcaecati perferendis dolor totam unde dolores quod maxime corporis officia et. Distinctio assumenda minima maiores.</p>
                  </div>
                  <div class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">
                     <h3 class="text-black h4 mb-4">What is your opening time?</h3>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis perferendis.</p>
                  </div>
                  <div class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">
                     <h3 class="text-black h4 mb-4">Can I accept both Paypal and Stripe?</h3>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis perferendis.</p>
                  </div>
                  <div class="mb-5 aos-init" data-aos="fade-up" data-aos-delay="100">
                     <h3 class="text-black h4 mb-4">What available is refund period?</h3>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis perferendis.</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <div class="site-section" id="press-section">
         <div class="container">
            <div class="row">
               <div class="col-lg-4 mb-5 mb-lg-0">
                  <div class="block-heading-1 aos-init" data-aos="fade-right" data-aos-delay="">
                     <h2>СОҢКУ МААЛЫМТТАР</h2>
                  </div>
               </div>
               <div class="col-lg-6">
                  <ul class="list-unstyled" v-for="(post,index) in posts" :key="index">
                     <li class="mb-5 aos-init" data-aos="fade-right" data-aos-delay="">
                        <img :src="post.avatarUrl" alt="" style="width:200px;border-radius:10px;">
                        <span class="d-block text-muted mb-3"><b>{{post.date}}</b></span>
                        <h6><a href="press-single.html" class="text-black">{{post.textTitle}}</a></h6>
                        <p>{{post.text}}</p>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
      <div class="site-section bg-light block-13 aos-init" id="testimonials-section" data-aos="fade">
         <div class="container">
            
            <div class="text-center mb-5">
               <div class="block-heading-1">
                  <h2>ОТЗЫВДАР</h2>
               </div>
            </div>
            <div class="owl-carousel nonloop-block-13 owl-loaded owl-drag">
               <div class="owl-stage-outer">
                  <div class="owl-stage" style="transform: translate3d(-1850px, 0px, 0px); transition: all 1s ease 0s; width: 4440px;">
                     <div class="owl-item cloned" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <figure><img src="/assets/img/person_3.jpeg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 mb-4 text-black">Акмөөр Мажитова</h3>
                              <blockquote class="mb-4">
                                 <p> Мен ЖРТга Жылдыз Арыстанбек кызынын жардамы менен даярдандым. Натыйжада 209 балл алдым жана бир нече университеттерге бюджеттик негизде кабыл алындым. Жылдыз эже сабактарында жеке эле эрежелерди айта бербестен, практикалык көнүгүүлөр менен иштетип жана анализ кылганды үйрөтөт. Бул силерге чыныгы ЖРТда чоң жардам берет. Жылдыз эженин дагы бир өзгөчөлүгү – ал бизге чет жактарга окууга дагы мотивация берип турат. Учурда мен Польшада бюджеттик негизде жогорку билим алып жатам. </p>
                              </blockquote>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item cloned" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <blockquote class="mb-4">
                                 <p> Менин атым Наргиза. Мен ОРТга шаарга барып окубай, үйдөн онлайн даярдандым. Тестирлөөнүн жыйынтыгында 209 упай алдым. Чынында мындай ийгиликке жетишиме “Zhyldyz” академиясы чоң жардам берди. Себеби, мен көргөн видео сабактар, иштеген тесттердин баары тест учурунда кыйналбай иштөөгө жардам берди. Сиздерде окуганыма эч өкүнгөн жокмун. Себеби, мен бюджетке өтүп, курска кортокон акчамды толук актай алдым. Баардык абитуриенттерге ийгилик каалайм. ЖРТдан тез арада даярданып, жогорку балл алгысы келгендер болсо, Жылдыз эженин курстарын сунуштайм. </p>
                              </blockquote>
                              <figure><img src="../../assets/img/person_1.jpeg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 text-black">Каразакова Наргиза </h3>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item cloned" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <figure><img src="../../assets/img/person_3.jpeg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 mb-4 text-black">Адилет Үсенов</h3>
                              <blockquote class="mb-4">
                                 <p> Мен учурунда Жылдыз Арыстанбек кызынан ОРТга даярдануу боюнча сабак алгам. Жыйынтыгында негизги тесттен 208 балл алдым. Албетте, кубандым. Учурда Москва шаарындагы эң мыкты университеттердин биринде финансы жана экономика боюнча билим алп жатам. Эженин сабактары жеке эле ОРТ менен чектелбей, анын үйрөткөндөрү Business English, IELTS, TOEFL сыяктуу дагы тесттерде жардам берип жатат. Жылдыз эженин дагы бир өзгөчөлүгү оор нерсени, жеткиликтүү тил менен түшүндүрүп берет. Силерге айтарым, мугалим тандоодон адашпагыла. </p>
                              </blockquote>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <blockquote class="mb-4">
                                 <p> Мен тестирлөөдөн 218 балл алып, президенттин колунан сыйлык алдым. Абдан кубанып турам. Бул ийгиликке жетүүдө бизге онлайн билим берген “Zhyldyz” академиясынын орду чоң. Кыска убакыттын ичинде эле өтө көп жаңы нерселерди үйрөндүк. ЖРТ аз калгандыктан жумасына 6, кээде 7 жолудан сабак алып жаттык. ЖРТдан келген суроолорго окшогон практикалык тесттер менен көп иштеп, анализ кылганды үйрөндүк. Чыныгы тестирлөөдө анын баары суралды жана мен үчүн туура жоопторун табуу оңой болду. “Zhyldyz” академиясына жана Жылдыз эжеге чоң рахмат. Ал эми баардык тест тапшыруучуларга ийгилик каалайм. </p>
                              </blockquote>
                              <figure><img src="images/jainagul.jpeg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 text-black">Алтынбек кызы Жайнагүл</h3>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <figure><img src="images/person_2.jpg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 mb-4 text-black">Амангулов Азат</h3>
                              <blockquote class="mb-4">
                                 <p>Мен ЖРТдан 216 балл алдым. Биз пандемия учуруна туш келип калып, тестирлөөгө кантип даярданабыз деп абдан бушайман болдук. Бирок, “Zhyldyz” академиясына жазылып, биринчи айдан кийин эле прогресс болгонун сездим. Айрыкча, окуу жана түшүнүү бөлүмүнөн кыйналчумун, бир айда эле көп нерсе өздөштүрүп чыктым. Ошол себептен Жылдыз эженин курсун баарыңарга сунуштайм. Айрыкча, бюджеттик негизде окуйм дегендер сөзсүз ЖРТга даярданып киргиле. </p>
                              </blockquote>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item active" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <blockquote class="mb-4">
                                 <p> Мен, Пайшан кызы Нуриза, ОРТдан 211 балл алдым. Пандемияга карабай, бизге сапаттуу онлайн билим берген “Zhyldyz” академиясына терең ыраазымын. Сиздердин курсунуздар чыныгы ОРТ да абдан жардам берди. Сиздер туура ой жүгүртүү, тез окуу, логиканы машыктырууга чоң жардам бердиңиздер. Түшүндүрүү методуңуз жана окуучуларга кылган мамилеңиз абдан жакты. Андан тышкары окуучулардын өз ара пикир алышуусу аркылуу да көп нерсе үйрөндүм. Сиздердин жамаатка ийгилик каалайм! </p>
                              </blockquote>
                              <figure><img src="../../assets/img/person_1.jpeg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 text-black">Пайшан кызы Нуриза</h3>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item active" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <figure><img src="../../assets/img/person_3.jpeg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 mb-4 text-black">Акмөөр Мажитова</h3>
                              <blockquote class="mb-4">
                                 <p> Мен ЖРТга Жылдыз Арыстанбек кызынын жардамы менен даярдандым. Натыйжада 209 балл алдым жана бир нече университеттерге бюджеттик негизде кабыл алындым. Жылдыз эже сабактарында жеке эле эрежелерди айта бербестен, практикалык көнүгүүлөр менен иштетип жана анализ кылганды үйрөтөт. Бул силерге чыныгы ЖРТда чоң жардам берет. Жылдыз эженин дагы бир өзгөчөлүгү – ал бизге чет жактарга окууга дагы мотивация берип турат. Учурда мен Польшада бюджеттик негизде жогорку билим алып жатам. </p>
                              </blockquote>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item active" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <blockquote class="mb-4">
                                 <p> Менин атым Наргиза. Мен ОРТга шаарга барып окубай, үйдөн онлайн даярдандым. Тестирлөөнүн жыйынтыгында 209 упай алдым. Чынында мындай ийгиликке жетишиме “Zhyldyz” академиясы чоң жардам берди. Себеби, мен көргөн видео сабактар, иштеген тесттердин баары тест учурунда кыйналбай иштөөгө жардам берди. Сиздерде окуганыма эч өкүнгөн жокмун. Себеби, мен бюджетке өтүп, курска кортокон акчамды толук актай алдым. Баардык абитуриенттерге ийгилик каалайм. ЖРТдан тез арада даярданып, жогорку балл алгысы келгендер болсо, Жылдыз эженин курстарын сунуштайм. </p>
                              </blockquote>
                              <figure><img src="../../assets/img/person_1.jpeg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 text-black">Каразакова Наргиза </h3>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <figure><img src="../../assets/img/person_3.jpeg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 mb-4 text-black">Адилет Үсенов</h3>
                              <blockquote class="mb-4">
                                 <p> Мен учурунда Жылдыз Арыстанбек кызынан ОРТга даярдануу боюнча сабак алгам. Жыйынтыгында негизги тесттен 208 балл алдым. Албетте, кубандым. Учурда Москва шаарындагы эң мыкты университеттердин биринде финансы жана экономика боюнча билим алп жатам. Эженин сабактары жеке эле ОРТ менен чектелбей, анын үйрөткөндөрү Business English, IELTS, TOEFL сыяктуу дагы тесттерде жардам берип жатат. Жылдыз эженин дагы бир өзгөчөлүгү оор нерсени, жеткиликтүү тил менен түшүндүрүп берет. Силерге айтарым, мугалим тандоодон адашпагыла. </p>
                              </blockquote>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item cloned" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <blockquote class="mb-4">
                                 <p> Мен тестирлөөдөн 218 балл алып, президенттин колунан сыйлык алдым. Абдан кубанып турам. Бул ийгиликке жетүүдө бизге онлайн билим берген “Zhyldyz” академиясынын орду чоң. Кыска убакыттын ичинде эле өтө көп жаңы нерселерди үйрөндүк. ЖРТ аз калгандыктан жумасына 6, кээде 7 жолудан сабак алып жаттык. ЖРТдан келген суроолорго окшогон практикалык тесттер менен көп иштеп, анализ кылганды үйрөндүк. Чыныгы тестирлөөдө анын баары суралды жана мен үчүн туура жоопторун табуу оңой болду. “Zhyldyz” академиясына жана Жылдыз эжеге чоң рахмат. Ал эми баардык тест тапшыруучуларга ийгилик каалайм. </p>
                              </blockquote>
                              <figure><img src="../../assets/img/person_2.jpeg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 text-black">Алтынбек кызы Жайнагүл</h3>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item cloned" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <figure><img src="../../assets/img/person_2.jpeg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 mb-4 text-black">Амангулов Азат</h3>
                              <blockquote class="mb-4">
                                 <p>Мен ЖРТдан 216 балл алдым. Биз пандемия учуруна туш келип калып, тестирлөөгө кантип даярданабыз деп абдан бушайман болдук. Бирок, “Zhyldyz” академиясына жазылып, биринчи айдан кийин эле прогресс болгонун сездим. Айрыкча, окуу жана түшүнүү бөлүмүнөн кыйналчумун, бир айда эле көп нерсе өздөштүрүп чыктым. Ошол себептен Жылдыз эженин курсун баарыңарга сунуштайм. Айрыкча, бюджеттик негизде окуйм дегендер сөзсүз ЖРТга даярданып киргиле. </p>
                              </blockquote>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item cloned" style="width: 370px;">
                        <div>
                           <div class="block-testimony-1 text-center">
                              <blockquote class="mb-4">
                                 <p> Мен, Пайшан кызы Нуриза, ОРТдан 211 балл алдым. Пандемияга карабай, бизге сапаттуу онлайн билим берген “Zhyldyz” академиясына терең ыраазымын. Сиздердин курсунуздар чыныгы ОРТ да абдан жардам берди. Сиздер туура ой жүгүртүү, тез окуу, логиканы машыктырууга чоң жардам бердиңиздер. Түшүндүрүү методуңуз жана окуучуларга кылган мамилеңиз абдан жакты. Андан тышкары окуучулардын өз ара пикир алышуусу аркылуу да көп нерсе үйрөндүм. Сиздердин жамаатка ийгилик каалайм! </p>
                              </blockquote>
                              <figure><img src="../../assets/img/person_1.jpeg" alt="Image" class="img-fluid rounded-circle mx-auto"></figure>
                              <h3 class="font-size-20 text-black">Пайшан кызы Нуриза</h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="owl-nav">
                  <div class="owl-prev"><span class="icon-arrow_back"></span></div>
                  <div class="owl-next"><span class="icon-arrow_forward"></span></div>
               </div>
               <div class="owl-dots">
                  <div class="owl-dot active"><span></span></div>
                  <div class="owl-dot"><span></span></div>
               </div>
            </div>
         </div>
      </div>
      <div class="site-section" id="actual-section">
         <div class="container">
            <div class="row">
               <div class="col-12 text-center mb-5">
                  <div class="block-heading-1 aos-init" data-aos="fade-up" data-aos-delay="">
                     <h2>Актуладуу маалыматтар</h2>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-6 col-lg-6 aos-init" data-aos="fade-up" data-aos-delay="">
                  <div>
                     <a href="single.html" class="mb-4 d-block"><img src="images/hero_2.jpg" alt="Image" class="img-fluid rounded"></a>
                     <h2><a href="single.html">How To Find Gold In Mining</a></h2>
                     <p class="text-muted mb-3 text-uppercase small"><span class="mr-2">January 18, 2019</span> By <a href="single.html" class="by">James Cooper</a></p>
                     <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat et suscipit iste libero neque. Vitae quidem ducimus voluptatibus nemo cum odio ab enim nisi, itaque, libero fuga veritatis culpa quis!</p>
                     <p><a href="#">Read More</a></p>
                  </div>
               </div>
               <div class="col-md-6 col-lg-6 aos-init" data-aos="fade-up" data-aos-delay="100">
                  <div>
                     <a href="single.html" class="mb-4 d-block"><img src="images/hero_3.jpg" alt="Image" class="img-fluid rounded"></a>
                     <h2><a href="single.html">How To Find Gold In Mining</a></h2>
                     <p class="text-muted mb-3 text-uppercase small"><span class="mr-2">January 18, 2019</span> By <a href="single.html" class="by">James Cooper</a></p>
                     <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat et suscipit iste libero neque. Vitae quidem ducimus voluptatibus nemo cum odio ab enim nisi, itaque, libero fuga veritatis culpa quis!</p>
                     <p><a href="#">Read More</a></p>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <Footer/>
   </div>
</div>
</template>

<script>

//import topHeader from '../../views/navigation/topHeader';
import Footer from '../../views/navigation/Footer';
import PreLoader from '../../components/preloader/PreLoader'
import { mapActions  } from 'vuex';
import {userService} from '../../_services/user.service';
import * as firebase from '@/firebase';
export default {
   components: {
      //topHeader,
      Footer,
      PreLoader
   },
   data () {
      return {
         
         dialog: false,
         loginModal: false,
         isLoading:false,
         tab: 0,
         tabs: [
            {name:"Логин", icon:"mdi-account",id:1},
            {name:"Регистрация", icon:"mdi-account-outline", id:2}
         ],
         valid: true,
         firstName: "",
         lastName: "",
         email: "",
         password: "",
         verify: "",
         username: "",
         signupForm: {
            name: '',
            title: '',
            email: '',
            password: '',
            firstName: "",
            lastName: "",
         },
         loginEmailRules: [
            v => !!v || "обязательно",
            v => /.+@.+\..+/.test(v) || "E-mail must be valid"
         ],
         emailRules: [
            v => !!v || "обязательно",
            v => /.+@.+\..+/.test(v) || "E-mail must be valid"
         ],

         show1: false,
         rules: {
            required: value => !!value || "Required.",
            min: v => (v && v.length >= 6) || "Min 6 characters"
         },
         posts:[]
      }
   },
   computed: {
      passwordMatch() {
         return () => this.signupForm.password === this.verify || "Password must match";
      },
      loggedIn () {
         return this.$store.state.account.user;
      },
   },
   created() {
      this.getPosts();
   },
   methods: {
      menuItems () {
         return this.menu
      },
      goTo (url) {
         this.link = url;
         switch (url) {
            case 'elementary':
               
               break;
            case 'voters':
               this.$router.push({ name: 'headerVoters' });
               break;
            case 'preIntermediate':
               this.$router.push({ name: 'pre-intermediate' });
               break;
         }
      },
       ...mapActions('account', [ 'login', 'logout' ,'signup']),
      submit() {
         if(this.signupForm.firstName && !this.loggedIn.title){
            console.log(this.signupForm)
            this.signup({
               email: this.signupForm.email,
               password: this.signupForm.password,
               name: this.signupForm.firstName,
               title: this.signupForm.firstName +' '+ this.signupForm.lastName
            })
            this.loginModal = false;
            
         }else if(!this.signupForm.firstName && !this.loggedIn.title){
            //console.log(this);
            const { email, password } = this;
            //console.log({email, password});
            if (email && password) {
               this.login({ email, password });

               this.loginModal = false;
               this.isLoading = true;
               setTimeout(()=>{
                     this.isLoading = false;
               },300)
               if(this.loggedIn.role == 'ADMIN'){
                  this.$router.push({ path: '/admin-page/tests' });
               }
               this.$router.push({ path: '/elementary' });
               
            }
         }
         
      },
      async getPosts() {
          this.isLoading = true;
          this.events = await firebase.postsCollection.get()
            .then((snapshot) => {
              snapshot.forEach((el) => {
                  this.posts.push({...el.data()})
              });
            })
            this.isLoading = false;
      },
      //firebase.usersCollection.doc(res.user.uid).get().then(snapshot => console.log(snapshot.data()))
      reset() {
         this.$refs.form.reset();
      },
      resetValidation() {
         this.$refs.form.resetValidation();
      },
      logOut() {
         userService.logout();
      },
      toElem () {
         if(!this.loggedIn.title){
            this.loginModal = true;
         }
         else if(this.loggedIn.role == 'STUDENT') {
            this.$router.push({ name: 'elementary' });
         }
         else if(this.loggedIn.role == 'ADMIN') {
            this.$router.push({ path: '/admin-page/elementary' });
         }
      },
      toBeginer() {
         this.$router.push({ path: '/beginner' });
      }
   }
}
</script>